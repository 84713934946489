<!--
   /**
      * contact.vue
      * @module {src/modules/public}
      * @desc 前端联系我们
      * @author shiqiu
      * @date 2021-01-20
      */
-->
<template>
  <div>
    <el-row type="flex" justify="center" style="margin-top: 5vw;">
      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">
        <div style="width: 100%; float: left;background-color: #fff;">
          <div style="padding: 20px 2.5%;">
            <el-row :gutter="10" class="margin_b_10 margin_t_10">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom: 10px;cursor: pointer">
                <el-row :gutter="10" class="boxShadow_no_border">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-row :gutter="10">
                      <el-col :span="24">
                        <p style="font-size: 24px; color: #333333; padding-top: 10px; padding-bottom: 10px;text-align: center;border-bottom: 1px solid #dedede">{{this.$route.name}}</p>
                      </el-col>
                      <el-col :span="24" style="margin-top: 20px">
                        <p style="font-size: 20px; color: #333333; padding-top: 10px; padding-bottom: 10px">广州富融信息技术有限公司</p>
                        <p style="font-size: 14px; color: #666; padding-top: 10px; padding-bottom: 10px; line-height: 25px">
                          咨询电话：13672460740
                        </p>
                        <p style="font-size: 14px; color: #666; padding-top: 10px; padding-bottom: 10px; line-height: 25px">
                          联系邮箱：service@rich-f.com
                        </p>
                        <p style="font-size: 14px; color: #666; padding-top: 10px; padding-bottom: 10px; line-height: 25px">
                          联系地址：广州市天河区天河北路663号广东省机械研究所8栋801
                        </p>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>

</template>

<script>
export default {
  name: 'Login',
  components: {},
  data () {
    return {
    }
  },
  mounted () {

  },
  watch: {

  },
  methods: {

  },
  created () {
  }
}
</script>

<style lang="scss" scoped>
body {
  background: #fff !important;
}
</style>
